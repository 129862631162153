<template>
  <div>
      <div class="mobile_view" style="justify-content: center;">
      <div class="container position-relative" style="padding:0px !important;margin: 0;max-width: 100% !important;">
        <div class="row shoe_box">
          <div id="sidebar" style="margin-left: -1px;position:relative;background-color: white;padding-bottom:3px">
            <div :class="{activeS : (displayMode==3)}" v-if="displayMode!=0 && displayMode!=2" @click="getLiveStreamingEvents()" style="cursor:pointer;padding-left:3px;padding-right:3px;position:absolute;right:-55.5px;top:35px;background-color:white;transform: rotate(90deg);z-index:99;color:#C0C0C0;box-shadow: 2px 2px 2px rgba(14, 14, 14, 0.175)" class="card">Live Events</div>
            <div @click="displayMode=1" :class="{activeS : (displayMode==1)}" v-if="displayMode!=0 && displayMode!=2" style="cursor:pointer;padding-left:3px;padding-right:3px;position:absolute;right:-55.5px;top:125px;background-color:white;transform: rotate(90deg);z-index:99;color:#C0C0C0;box-shadow: 2px 2px 2px rgba(14, 14, 14, 0.175)" class="card">Video Feed</div>
            <div :class="{activeS : (displayMode==4)}" v-if="displayMode!=0 && displayMode!=2" style="cursor:pointer;padding-left:3px;padding-right:3px;position:absolute;right:-53.5px;top:215px;background-color:white;transform: rotate(90deg);z-index:99;color:#C0C0C0;box-shadow: 2px 2px 2px rgba(14, 14, 14, 0.175)" class="card">Upcoming</div>
            <div  id="scrolldiv" style="position:relative;height:91vh;overflow-y:scroll;max-height:100%">
              <div v-if="displayMode==1">
                <div @click="changePage(currPage,-1)" v-if="currPage!=1" style="cursor:pointer;padding-top:3px;padding-bottom:3px;background-color: #F43B55;color:white" class="hh"> &lt;&lt; Page {{currPage-1}}</div>
                <div class="hh" v-for="(i,index) in videoDetailsList" :key="index">
                    <img @click="playVideo(index,i)" class="playv" width="35px" height="35px" src=".././assets/play-icon2.png">
                    <div v-if="i.thumbnailUrls" class = "mousehvr" @click="playVideo(index,i)" style="margin: 2px; padding-right: 0px;padding-left: 0px;margin-right: 0;margin-left: 0;padding-bottom: 10px;">
                      <img width="115px" v-if="i.thumbnailUrls" :src="i.thumbnailUrls" style="height: 130px;width: 100%;object-fit: cover;padding:none">
                      <div style="font-size: 12px;font-family: sans-serif;">{{i.title}}</div>
                    </div>
                </div>
                <div @click="changePage(currPage,1)" style="cursor:pointer;padding-top:3px;padding-bottom:3px;background-color: #F43B55;color:white" class="hh">Page {{currPage+1}} >></div>
              </div>
              <div v-if="displayMode==3" style="min-width:100px;background:white">
                <div  class="hh" v-for="(i,index) in liveVideoList" :key="index">
                    <img @click="playLiveVideo(index,i)" class="playv" width="35px" height="35px" src=".././assets/play-icon2.png">
                    <div v-if="i.thumbnail" class = "mousehvr" @click="playLiveVideo(index,i)" style="margin: 2px; padding-right: 0px;padding-left: 0px;margin-right: 0;margin-left: 0;padding-bottom: 10px;">
                      <img width="115px" v-if="i.thumbnail" :src="i.thumbnail" style="height: 130px;width: 100%;object-fit: cover;padding:none">
                      <div style="font-size: 12px;font-family: sans-serif;">{{i.title}}</div>
                    </div>
                </div>
              </div>
              <div v-if="displayMode==2">
                <div class="hh" v-for="(i,index) in productDetailsList" :key="index">
                    <div v-if="i.images[0]" class = "mousehvr" @click="showProduct(i)" style="margin: 2px; padding-right: 0px;padding-left: 0px;margin-right: 0;margin-left: 0;padding-bottom: 15px;cursor:pointer">
                      <img v-if="i.images[0]" :src="i.images[0]" style="height: 130px;width: 100%;padding:none">
                      <div style="font-size: 12px;font-family: sans-serif;">{{i.name}}</div>
                    </div>
                </div>
              </div>
            </div>
            <button v-if="displayMode==2" @click="openGiftPromo()" class="buy-btn">BUY</button>
          </div>
        </div>
          <div  class="row video_box">
          <div class="col-md-12 p-0">
            <div class="videoDiv" id="videobox">
              <div v-show="!isVideoCallActive && !isLiveStreamActive" class="videoSection" id="inbox">
                <video  playsinline @click="displayMode=0" preload="auto" id="video-elem" muted loop autoplay>
                  <source :src="currentVideoObject.videoUrls.MP4[0]" v-if="currentVideoObject.videoUrls">
                  Your browser does not support HTML5 video.
                </video>
                <div class="row">
                    <div>
                      <i class="fa fa-eye" width="100px" height="80px" aria-hidden="true"></i>
                      <div class="viewcount">{{viewsCurrVideo}}</div>
                    </div>
                    <div><img width="60px" height="60px" class="brandlogo" :src="brandurl"></div>
                    <div><img width="80px" height="70px" class="wtvlogo" src=".././assets/icons/wtv-icon.svg"></div>
                </div>
                <!--<vodal :width=250 :height="380" :show="showPromoCodeModal" animation="rotate" @hide="showPromoCodeModal = false">
                    <div style="margin-top:15px">
                        <h4 style="color:#F43B55 !important;padding-top:0px;font-weight:bold">Exclusive Offer</h4>
                        <br>
                        <span style="font-size:20px;margin-top:6px;font-weight:bold">Avail Upto {{promocodeDiscountPercent}}% discount</span>
                        <br><br>
                        <span style="font-size:17px;font-weight:bold">{{promocodeText}}</span>
                        <br><br>
                        <p style="font-size:17px;font-weight:bold">Proceed to the brand website by clicking the button below</p>
                        <button  @click="redirectBuy()" style="width:170px" class="buy-btn">BUY</button>
                    </div>
                </vodal>-->
                <!--<vodal :width="350" :height="500" :show="showPromoCodeModal" animation="rotate" @hide="showPromoCodeModal = false">
                    <div style="height:485px;overflow-y:scroll">
                        <h4 style="color:#F43B55 !important;padding-top:0px;font-weight:bold;font-size:20px">Select Your Options</h4>
                        <br>
                        <div v-for="(item,index) in productDetailsList" :key="index">
                          <div class="card container" style="display:flex;align-items:center;justify-content:center;min-height:40px;font-size:12px;background-color:#ffd0d7;border-radius:2px;border:1px solid #ffd0d7">
                            {{item.name}}
                          </div>
                          <div class="row" style="box-shadow: 0 4px 4px rgba(14, 14, 14, 0.175);padding-top:4px;padding-bottom:4px">
                            <div v-for="(itemj,indexj) in item.ecommRedirect" :key="indexj" class="col-sm-6" style="max-width:150px !important;display:flex;justify-content:center;align-items:center">
                              <img style="width:30px;width:30px" src=".././assets/icons/platform.png"/>
                              <button style="border:none;width:100px;font-size:14px" @click="redirectBuy(itemj.link)">Buy on {{itemj.platform}}</button>
                            </div>
                          </div>
                          <br>
                        </div>
                    </div>
                </vodal>-->
                <vodal class="mymodal" :width="220" :height="400" :show="showProductObj" animation="rotate" @hide="showProductObj = false;product={}">
                    <div style="color:white;background-color:#999;padding:5px">{{brandname}}</div>
                    <div style="padding:10px;max-height:350px;overflow-y:scroll">
                      <img width="170" height="170" :src="product.url">
                      <br><br><br>
                      <span style="font-size:15px">{{product.title}}</span>
                      <br><br>
                      <span style="font-size:17px;font-weight:bold">Rs {{product.price}}</span>
                    </div>
                </vodal>
                <vodal class="mymodal" :width=200 :height="215" :show="isSharePopupVisible" animation="rotate" @hide="isSharePopupVisible = false;">
                    <div style="color:white;background-color:#999;padding:5px">Share On :</div>
                    <br>
                    <div class="row center-it">
                      <div class="col-2 sm-icons">
                        <facebook  :url="pageurl" :title="currentVideoObject.title" scale="3"></facebook>
                      </div>
                      <div class="col-1 sm-icons">
                      </div>
                      <div class="col-2 sm-icons">
                        <twitter :url="pageurl" :title="currentVideoObject.title" scale="3"></twitter>
                      </div>
                    </div>
                    <br>
                    <div class="row center-it">
                      <div class="col-2 sm-icons">
                        <telegram :url="pageurl" :title="currentVideoObject.title" scale="3"></telegram>
                      </div>
                      <div class="col-1 sm-icons">
                      </div>
                      <div class="col-2 sm-icons"><!--<a :href="'https://api.whatsapp.com/send/?phone&text='+encodeURIComponent(pageurl)" target="_blank" data-action="share/whatsapp/share"><whats-app :url="pageurl" class="disabledbutton"  :title="currentVideoObject.title" scale="3"></whats-app></a>--> 
                        <whats-app :url="pageurl" :title="currentVideoObject.title" scale="3"></whats-app>
                      </div>
                    </div>
                </vodal>
                <div v-show="currentVideoObject.videoUrls">
                  <div @click="startVideoCall()"><img width="40px" height="40px" class="vcall" src=".././assets/icons/vuser.png"></div>
                  <div v-if="!isGiftIconClicked" @click="openGiftPromo()"><img width="35px" height="35px" class="gift" src=".././assets/icons/9.svg"></div>
                  <div v-if="isGiftIconClicked" @click="openGiftPromo()"><img width="35px" height="35px" class="gift" src=".././assets/icons/9.svg"></div>
                  <div @click="fullScreenVideo()"><img width="35px" height="35px" class="expand" src=".././assets/icons/expand.svg"></div>
                  <div v-if="isVideoMuted" @click="playTheVideo()"><img width="35px" height="35px" class="speak" src=".././assets/icons/volume_off.svg"></div>
                  <div v-if="!isVideoMuted" @click="pauseTheVideo()"><img width="35px" height="35px" class="mute" src=".././assets/icons/volume_on.svg"></div>
                  <div @click="displayMode=1" v-if="displayMode!=1"><img class="camera" width="35px" height="35px" src=".././assets/icons/7.svg"></div>
                  <div @click="displayMode=1" v-if="displayMode==1"><img class="camera" width="35px" height="35px" src=".././assets/icons/8.svg"></div>
                  <div @click="fetchProductDetails" v-if="displayMode!=2"><img class="cart" width="35px" height="35px" src=".././assets/icons/6.svg"></div>
                  <div @click="fetchProductDetails" v-if="displayMode==2"><img class="cart" width="35px" height="35px" src=".././assets/icons/5.svg"></div>
                  <div><img @click="isSharePopupVisible=true;displayMode=0;" class="share" width="35px" height="35px" src=".././assets/icons/3.svg">
                  </div>
                  <div @click="likeCurrVideo()" v-if="!isVideoLiked"><img class="like" width="35px" height="35px" src=".././assets/icons/1.svg"></div>
                  <div v-if="isVideoLiked"><img class="like" width="35px" height="35px" src=".././assets/icons/2.svg"></div>

                  <div v-if="displayMode==0" @click="followUser()" class="userprofile">
                    <img class="userprofileicon" width="31px" height="31px" src=".././assets/profile.png">{{videoUserName}}<button style="width:85px;height:30px;background-color:white;color:#F43B55;font-size:16px;border-radius:15px;margin-left:10px">{{followStatus}}</button>
                  </div>
                </div>
              </div>
              <div v-show="isVideoCallActive" id="callDiv" class="callsection" style="width:100%;background-color:black">
                  <div><img @click="disconnectCall" class="calld" width="45px" height="45px" src=".././assets/icons/calld.png"></div>
                  <div v-if="isAudioEnabledForVideoCall"><img @click="muteCall" class="vcallmute" width="45px" height="45px" src=".././assets/icons/vmute.png"></div>
                  <div v-if="!isAudioEnabledForVideoCall"><img @click="unmuteCall" class="vcallmute" width="45px" height="45px" src=".././assets/icons/vunmute.png"></div>
                  <div v-if="isVideoEnabled"><img @click="disableVideo" class="vuser" width="45px" height="45px" src=".././assets/icons/vuser.png"></div>
                  <div v-if="!isVideoEnabled"><img @click="enableVideo" class="vuser" width="45px" height="45px" src=".././assets/icons/vuser1.png"></div>
                  <!--<div><img class="vcomment" width="45px" height="45px" src=".././assets/icons/vcomment.png"></div>-->


                  <div v-show="isVideoEnabled" id="me" style=" width: 110px;height: 180px;right: 0px;position: absolute;z-index: 900"></div>
                  <div id="remote-container" style="width: 100%;height: 0%"></div>
                  <div id="canvas-container" style="width: 100%;height: 100%"></div>
              </div>
              <div v-show="isLiveStreamActive" id="liveStreamDiv" class="callsection streamdiv" style="width:100%;background-color:black">
                  <div><img @click="disconnectStream" class="calld1" width="31px" height="31px" src=".././assets/icons/cross.webp"></div>
                  <div @click="displayMode=1" v-if="displayMode!=1"><img class="camera" width="35px" height="35px" src=".././assets/icons/7.svg"></div>
                  <div @click="displayMode=1" v-if="displayMode==1"><img class="camera" width="35px" height="35px" src=".././assets/icons/8.svg"></div>
                  <div @click="fetchProductDetailsLive" v-if="displayMode!=2"><img class="cart" width="35px" height="35px" src=".././assets/icons/6.svg"></div>
                  <div @click="fetchProductDetailsLive" v-if="displayMode==2"><img class="cart" width="35px" height="35px" src=".././assets/icons/5.svg"></div>
                  <div v-if="!isGiftIconClicked" @click="openGiftPromo()"><img width="35px" height="35px" class="giftlive" src=".././assets/icons/9.svg"></div>
                  <div v-if="isGiftIconClicked" @click="openGiftPromo()"><img width="35px" height="35px" class="giftlive" src=".././assets/icons/9.svg"></div>
                  <!--<div v-if="isAudioEnabledForStream"><img @click="muteStream()" class="vcallmute" width="45px" height="45px" src=".././assets/icons/vmute.png"></div>
                  <div v-if="!isAudioEnabledForStream"><img  @click="unmuteStream()" class="vcallmute" width="45px" height="45px" src=".././assets/icons/vunmute.png"></div>-->
                  <div id="remote-container-live" style="width: 100%;height: 0%"></div>
                  <div id="canvas-container-live" style="width: 100%;height: 100%"></div>
              </div>
            </div>
            <vodal :width="350" :height="500" :show="showPromoCodeModal" animation="rotate" @hide="showPromoCodeModal = false">
                    <div style="height:485px;overflow-y:scroll">
                        <h4 style="color:#F43B55 !important;padding-top:0px;font-weight:bold;font-size:20px">Select Your Options</h4>
                        <br>
                        <div v-for="(item,index) in productDetailsList" :key="index">
                          <div class="card container" style="display:flex;align-items:center;justify-content:center;min-height:40px;font-size:12px;background-color:#ffd0d7;border-radius:2px;border:1px solid #ffd0d7">
                            {{item.name}}
                          </div>
                          <div class="row" style="box-shadow: 0 4px 4px rgba(14, 14, 14, 0.175);padding-top:4px;padding-bottom:4px">
                            <div v-for="(itemj,indexj) in item.ecommRedirect" :key="indexj" class="col-sm-6" style="max-width:150px !important;display:flex;justify-content:center;align-items:center">
                              <img style="width:30px;width:30px" src=".././assets/icons/platform.png"/>
                              <button style="border:none;width:100px;font-size:14px" @click="redirectBuy(itemj.link)">Buy on {{itemj.platform}}</button>
                            </div>
                          </div>
                          <br>
                        </div>
                    </div>
            </vodal>
          </div>
        </div>  
      </div>
    </div>
  </div>
</template>

<script>
try
{
  window.scrollTo(0, 0);
}
catch(e)
{
  console.log(e);
}

import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSocialSharing from 'vue-social-sharing'
import Vodal from 'vodal';
import "vodal/common.css";
import "vodal/rotate.css";
import {
  Facebook,
  Twitter,
  WhatsApp,
  Telegram
} from "vue-socialmedia-share";
Vue.component(Vodal.name, Vodal);
Vue.use(VueAxios, axios)
Vue.use(VueSocialSharing);
import VueSimpleAlert from "vue-simple-alert";

Vue.use(VueSimpleAlert);
import AgoraRTC from 'agora-rtc-sdk';
import AgoraRTM from 'agora-rtm-sdk'
var hidden, visibilityChange; 
try{
  if (typeof document.hidden !== "undefined") {  
    hidden = "hidden";
    visibilityChange = "visibilitychange";
  } else if (typeof document.msHidden !== "undefined") {
    hidden = "msHidden";
    visibilityChange = "msvisibilitychange";
  } else if (typeof document.webkitHidden !== "undefined") {
    hidden = "webkitHidden";
    visibilityChange = "webkitvisibilitychange";
  }
}
catch(e)
{
  console.log(e)
}

export default {
  name: 'Video',
  props: {
  },
  components: {
    Facebook,
    Twitter,
    Telegram,
    WhatsApp
  },
  data() {
      return {
        videoDetailsList:[],
        productDetailsList:[],
        productDetailsListLive:[],
        currentVideoObject:{},
        isVideoMuted:true,
        brandLogoURL:'',
        displayMode:0,
        myloader:'',
        isVideoLiked:false,
        isGiftIconClicked:false,
        viewsCurrVideo:0,
        showPromoCodeModal:false,
        showPromoCodeModalLive:false,
        promocodeDiscountPercent:'',
        promocodeText:'',
        vendorUrl:'',
        brandname:'',
        showProductObj:false,
        product:{
          url:'',
          price:'',
          title:''
        },
        isSharePopupVisible:false,
        videoUserName:'',
        followStatus:'Follow',
        currPage:1,
        showVideoCallPopUp:false,
        callWidth:0,
        callHeight:0,
        isVideoCallActive:false,
        client:{},
        streamClient:{},
        localStream:{},
        liveLocalStream:{},
        isVideoEnabled:true,
        isAudioEnabledForVideoCall:true,
        resellerId:'',
        liveVideoList:[],
        isLiveStreamActive:false,
        isAudioEnabledForStream:true,
        currLivestreamObj:{}
      }
  },
  watch:{
    'videoPlayList':function(val)
    {
      console.log(val);
    },
    'displayMode':function(val)
    {
      console.log(val);
      document.getElementById('sidebar').style.height = (document.getElementById('videobox').clientHeight)/1.0233 + 'px';
      if(val==1)
      {
        document.getElementById('scrolldiv').style.height = (document.getElementById('videobox').clientHeight)/1.0233 + 'px';
      }
      else
      {
        document.getElementById('scrolldiv').style.height = '91vh';
      }
    }
  },
  computed: {
    pageurl:function()
    {
      var url = window.location.origin+ '/';
      if(sessionStorage.getItem('userId'))
      {
        url = url + this.$route.params.brand + '/' + this.currentVideoObject.video_id + '?uid=' + sessionStorage.getItem('userId');
      }
      else
      {
        url = url + this.$route.params.brand + '/' + this.currentVideoObject.video_id;
      }
      return url;
    },
    brandurl:function()
    {
      var url = window.location.origin + '/assets/logo/logo.svg';
      return url;
    },
    videoPlayList:function()
    {
      if(this.videoDetailsList.length>0)
      {
        var tempList = [];
        for(var i=0;i<this.videoDetailsList.length;i++)
        {
          if(this.videoDetailsList[i].id != this.currentVideoObject.id)
          {
            tempList.push(this.videoDetailsList[i]);
          }
        }
        return tempList;
      }
      else
      {
        var x = [];
        return x;
      }
    }
  },
  mounted()
  {
    try
    {
      document.getElementById('sidebar').style.height = (document.getElementById('videobox').clientHeight)/1.0233 + 'px';
      window.scrollTo(0, 0);
    }
    catch(e)
    {
      console.log(e);
    }
    document.addEventListener(visibilityChange, this.handleVisibilityChange, false);
  },
  created()
  {
    if(sessionStorage.getItem("accessToken")=='' || sessionStorage.getItem("accessToken")==null)
    { 
      var brand = this.$route.params.brand;
      if(brand.length>0)
      {
        this.$router.push({ path: '/'+ this.$route.params.brand});
      }
      else
      {
        console.log('sayonaraa33333')
        this.$router.push({ path: '/404notfound'}); 
      }
    }
    else
    {
      var brandname = this.$route.params.brand;
      console.log(brandname);
      this.getBrandID();
    }
    window.addEventListener("resize", this.myEventHandler);
    screen.orientation.lock("portrait-primary");
  },
  destroyed() {
  window.removeEventListener("resize", this.myEventHandler);
  },
  methods:{
    fetchProductDetailsLive()
    {
      console.log('hola');
      this.startLoader();
      const myheaders = {
        'deviceId':sessionStorage.getItem('deviceId'),
        'userId':sessionStorage.getItem('userId'),
        'accessToken':sessionStorage.getItem("accessToken"),
      }
      console.log(myheaders);
      axios.get(`https://api.wakaw.live/v1/link/event?live_event_id=` + this.currLivestreamObj.id,
        {
          headers:myheaders 
        }).then(response => {
          this.stopLoader();
          console.log(response);
          console.log(response.data.data[0].teaser_video_id);
          this.getLiveStreamProductList(response.data.data[0].teaser_video_id);
        })
        .catch(e => {
          this.stopLoader();
          this.errors.push(e)
        })
    },
    getLiveStreamProductList(videoId)
    {
      this.startLoader();
      const myheaders = {
        'deviceId':sessionStorage.getItem('deviceId'),
        'userId':sessionStorage.getItem('userId'),
        'accessToken':sessionStorage.getItem("accessToken"),
      }
      console.log(myheaders);
      axios.get(`https://api.wakaw.live/v1/api/products/` + videoId,
        {
          headers:myheaders 
        }).then(response => {
          this.stopLoader();
          console.log(response);
          this.productDetailsList = response.data.data.product_ids;
          this.displayMode = 2;
          this.vendorUrl = response.data.data.product_ids[0].vendor_url;
        })
        .catch(e => {
          this.stopLoader();
          this.errors.push(e)
        })
    },
    unmuteStream()
    {
      
    },
    muteStream()
    {
    },
    getLiveStreamingEvents()
    {
        this.startLoader();
        const myheaders = {
          'deviceId':sessionStorage.getItem('deviceId'),
          'userId':sessionStorage.getItem('userId'),
          'accessToken':sessionStorage.getItem("accessToken"),
        }
        axios.post(`https://api.wakaw.live/v1/event/0/10`,
        {
              name:"",
              startDate:"",
              endDate:"",
              brandId:this.$route.params.brand
        },{headers: myheaders}).then(response => {
          console.log(response);
          this.stopLoader();
          this.liveVideoList = response.data.data;
          let height = document.getElementById('videobox').clientHeight;
          document.getElementById('liveStreamDiv').style.height = height + 'px';
          if(this.liveVideoList.length <= 0)
          {
            this.$confirm("No Live Events Found", "Error", "error")
              .then(r => {
                console.log(r);
                console.log("OK selected.");
              })
              .catch(() => {
                console.log("OK not selected.");
              });
          }
          else
          {
            this.displayMode = 3;
          }
        }).catch(e => {
          this.stopLoader();
          this.errors.push(e)
        });
    },
    getReSeller(appId,token,channel,userid)
    {
        const myheaders = {
          'deviceId':sessionStorage.getItem('deviceId'),
          'userId':sessionStorage.getItem('userId'),
          'accessToken':sessionStorage.getItem("accessToken"),
        }
        axios.get(`https://api.wakaw.live/v1/brandseller?brandId=`+this.$route.params.brand,
        {
            headers: myheaders
        }).then(response => {
          console.log(response.data);
          this.resellerId = response.data.data[0].userId;
          this.joinRTM(appId,token,channel,userid,this.resellerId);
        });      
    },
    muteCall()
    {
      this.isAudioEnabledForVideoCall = false;
      this.localStream.muteAudio();
    },
    unmuteCall()
    {
      this.isAudioEnabledForVideoCall = true;
      this.localStream.unmuteAudio();
    },
    disconnectCall()
    {
      this.localStream.close();
      let remDiv=document.getElementById('me');
      remDiv.innerHTML = "";
      this.client.leave();
      this.localStream = {};
      this.client = {};
      this.showVideoCallPopUp = false;
      this.isVideoCallActive = false;
    },
    disconnectStream()
    {
      this.streamClient.leave();
      this.streamClient = {};
      this.isLiveStreamActive = false;
      window.location.reload();
    },
    disableVideo()
    {
      //this.localStream.disableVideo();
      //let vid = document.getElementById('video'+this.localStream.getId());  
      this.localStream.muteVideo();
      //console.log(this.localStream.getVideoTrack());
      this.isVideoEnabled = false;
    },
    enableVideo()
    {
      //this.localStream.play('me');
      this.localStream.unmuteVideo();
      this.isVideoEnabled = true;
    },
    startVideoCall()
    {
        const myheaders = {
          'deviceId':sessionStorage.getItem('deviceId'),
          'userId':sessionStorage.getItem('userId'),
          'accessToken':sessionStorage.getItem("accessToken"),
        }
        axios.post(`https://api.wakaw.live/v1/videocall`,
        {
            brandId:this.$route.params.brand,
            videoId:this.currentVideoObject.video_id,
            products:"12334"
        },{headers: myheaders}).then(response => {
          console.log(response.data.data);
          let appId = response.data.data.agora_app_id;
          let token = response.data.data.agora_token;
          let channel = response.data.data.video_call_id;
          let uid = sessionStorage.getItem('userId')
          this.showVideoCallPopUp = true;
          let height = document.getElementById('videobox').clientHeight;
          this.isVideoCallActive = true;
          this.displayMode = 0;
          document.getElementById('callDiv').style.height = height + 'px';
          this.joinChannel(appId,token,channel,uid);
        })
    },
    joinChannel(appId,token,channel,userid)
    {
      this.client = AgoraRTC.createClient({
          mode: 'live',
          codec: "h264"
      });
      this.client.init(appId,() => console.log("AgoraRTC client initialized") ,this.handleFail);
      console.log(token+" "+channel+" "+userid);
      var vm = this;
      vm.subscribeStreamEvents();
      //this.client.join('006bb81a1a30f4a4b7aaf43b235eb14d1caIADiFscsw10yfHY1/oWGFKCKrlR5GDiwwx/DXWAbNVF7keI9uXN4krOXIgC3zjw16MQ7YAQAAQB4gTpgAgB4gTpgAwB4gTpgBAB4gTpg','3b0fdb94-8f8d-4c84-b364-4beb8c240332',userid, (uid)=>{
      this.client.join(token,channel,userid, (uid)=>{
      this.localStream = AgoraRTC.createStream({
              streamID: userid,
              audio: true,
              video: true,
              screen: false
          });
          console.log(uid);
          // Associates the stream to the client
          vm.localStream.init(function() {

              //Plays the localVideo
              vm.localStream.setVideoEncoderConfiguration({
                // Video resolution
                resolution: {
                    width: 360,
                    height: 640
                },
              })
              vm.localStream.play('me');
              console.log(vm.client);
              console.log('----------------');
              //Publishes the stream to the channel
              vm.client.publish(vm.localStream, vm.handleFail);
              let myDiv=document.getElementById('video'+vm.localStream.getId());
              myDiv.style.position = "relative"
              vm.getReSeller(appId,token,channel,userid);

          },vm.handleFail);
      
      },vm.handleFail);
    },
    promoShow()
    {
      const myheaders = {
        'deviceId':sessionStorage.getItem('deviceId'),
        'userId':sessionStorage.getItem('userId'),
        'accessToken':sessionStorage.getItem("accessToken"),
      }
      console.log(myheaders);
      axios.get(`https://api.wakaw.live/v1/api/products/` + this.currentVideoObject.video_id,
        {
          headers:myheaders 
        }).then(response => {
          console.log(response);
          this.productDetailsList = response.data.data.product_ids;
          for(let i in this.productDetailsList)
          {
            let myObj = this.productDetailsList[i].ecommRedirect;
            let myList = [];
            for(let prop in myObj)
            {
              let tempObj = {};
              if(Object.prototype.hasOwnProperty.call(myObj, prop))
              {
                tempObj.platform = prop;
                tempObj.link = myObj[prop];
                myList.push(tempObj);
              }
            }
            this.productDetailsList[i].ecommRedirect = myList;
          }
          this.showPromoCodeModal = true;
        })
        .catch(e => {
          this.errors.push(e)
        })
    },
    joinRTM(appId,token,channel,userid,resellerId)
    {
      console.log(appId+''+token+''+channel+''+userid);
        const myheaders = {
          'deviceId':sessionStorage.getItem('deviceId'),
          'userId':sessionStorage.getItem('userId'),
          'accessToken':sessionStorage.getItem("accessToken"),
        }
        axios.get(`https://api.wakaw.live/v1/rtm/token`,
        {
            headers: myheaders
        }).then(response => {
          console.log(response.data.data);
          const clientRtm = AgoraRTM.createInstance(appId); 
              clientRtm.login({ token: response.data.data.agora_rtm_token, uid: sessionStorage.getItem('userId') }).then(() => {
                console.log('AgoraRTM client login success');
                console.log(resellerId);
                let rtmObj = clientRtm.createLocalInvitation(resellerId);
                rtmObj.content = channel;
                rtmObj.send();
              }).catch(err => {
                console.log('AgoraRTM client login failure', err);
              });
        })
              /*const clientRtm = AgoraRTM.createInstance(appId); 
              clientRtm.login({ token: token, uid: userid }).then(() => {
                console.log('AgoraRTM client login success');
              }).catch(err => {
                console.log('AgoraRTM client login failure', err);
              });*/
    },
    subscribeStreamEventsForLiveStreaming()
    {
      console.log('@@@@@@@@@@');
      var vm = this;
      vm.streamClient.on('stream-added', function (evt){
          vm.streamClient.subscribe(evt.stream, vm.handleFail);
          console.log('yash2');
          this.liveLocalStream = evt.stream;
      });
      //When you subscribe to a stream
      vm.streamClient.on('stream-subscribed', function (evt) {
        console.log('yash');
          this.liveLocalStream = evt.stream;
          let stream = evt.stream;
          vm.addVideoStreamLive(stream.getId());
          stream.play(stream.getId());
          vm.addCanvasLive(stream.getId());
          console.log('holllla2');
          vm.isLiveStreamActive = true;
      });
      vm.streamClient.on('streamInjectedStatus', function (evt) {
        console.log('yash1');
      console.log("Injected Steram Status Updated");
      // evt.stream.play('full-screen-video');
      console.log(JSON.stringify(evt));
    });
      //When a person is removed from the stream
      vm.streamClient.on('stream-removed',vm.removeVideoStreamLive);
      vm.streamClient.on('peer-leave',vm.removeVideoStreamLive);
    },
    subscribeStreamEvents()
    {
      console.log('@@@@@@@@@@');
      var vm = this;
      vm.client.on('stream-added', function (evt){
          vm.client.subscribe(evt.stream, vm.handleFail);
          console.log('holllla')
      });
      //When you subscribe to a stream
      vm.client.on('stream-subscribed', function (evt) {
          let stream = evt.stream;
          vm.addVideoStream(stream.getId());
          stream.play(stream.getId());
          vm.addCanvas(stream.getId());
          console.log('holllla2')
      });
      //When a person is removed from the stream
      vm.client.on('stream-removed',vm.removeVideoStream);
      vm.client.on('peer-leave',vm.removeVideoStream);
    },
 addVideoStream(streamId){
    let streamDiv=document.createElement("div"); // Create a new div for every stream
    streamDiv.id=streamId;  
    streamDiv.style.position = "fixed";                     // Assigning id to div
    streamDiv.style.transform="rotateY(180deg)"; // Takes care of lateral inversion (mirror image)
    let remoteContainer = document.getElementById("remote-container");
    remoteContainer.appendChild(streamDiv);      // Add new div to container
},
addVideoStreamLive(streamId){
    let streamDiv=document.createElement("div"); // Create a new div for every stream
    streamDiv.id=streamId;  
    streamDiv.style.position = "fixed";                     // Assigning id to div
    streamDiv.style.transform="rotateY(180deg)"; // Takes care of lateral inversion (mirror image)
    let remoteContainer = document.getElementById("remote-container-live");
    remoteContainer.appendChild(streamDiv);      // Add new div to container
},
 removeVideoStream (evt) {
   console.log('hola');
    let stream = evt.stream;
    stream.stop();
    let remDiv = document.getElementById(stream.getId());
    remDiv.parentNode.removeChild(remDiv);
    console.log("Remote stream is removed " + stream.getId());
},
removeVideoStreamLive(evt) {
   console.log('hola');
    let stream = evt.stream;
    stream.stop();
    let remDiv = document.getElementById(stream.getId());
    remDiv.parentNode.removeChild(remDiv);
    console.log("Remote stream is removed " + stream.getId());
    this.streamClient.leave();
    this.streamClient = {};
    this.isLiveStreamActive = false;
    window.location.reload();
},
 addCanvas(streamId){
    let canvas=document.createElement("canvas");
    canvas.id='canvas'+streamId;
    let canvasContainer = document.getElementById("canvas-container");
    canvasContainer.appendChild(canvas);
    let ctx = canvas.getContext('2d');
    let video=document.getElementById(`video${streamId}`);
    video.addEventListener('loadedmetadata', function() {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
    });

    video.addEventListener('play', function() {
        var $this = this; //cache
        (function loop() {
            if (!$this.paused && !$this.ended) {
                ctx.drawImage($this, 0, 0);
                setTimeout(loop, 1000 / 30); // drawing at 30fps
            }
        })();
    }, 0);
},
 addCanvasLive(streamId){
    let canvas=document.createElement("canvas");
    canvas.id='canvas'+streamId;
    let canvasContainer = document.getElementById("canvas-container-live");
    canvasContainer.appendChild(canvas);
    let ctx = canvas.getContext('2d');
    let video=document.getElementById(`video${streamId}`);
    video.addEventListener('loadedmetadata', function() {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
    });

    video.addEventListener('play', function() {
        var $this = this; //cache
        (function loop() {
            if (!$this.paused && !$this.ended) {
                ctx.drawImage($this, 0, 0);
                setTimeout(loop, 1000 / 30); // drawing at 30fps
            }
        })();
    }, 0);
},
    changePage(currPage,pageChange)
    {
      this.getVideoFeedForBrandPageChange(currPage,this.$route.params.brand,pageChange);
    },
    handleVisibilityChange()
    {
      var videoElement = document.getElementById("video-elem");
      if(document[hidden] && videoElement.style.display != 'none')
      {
        console.log('aaa')
        videoElement.pause();
      }
      else
      {
        console.log('bbb')
        videoElement.play();
      }
    },
    recordVideoShare(uid,vId)
    {
        const myheaders = {
          'deviceId':sessionStorage.getItem('deviceId'),
          'userId':sessionStorage.getItem('userId'),
          'accessToken':sessionStorage.getItem("accessToken"),
        }
        axios.post(`https://api.wakaw.live/v1/api/analytics/videoshare/view`,
        {
          shared_by_user_id:uid,
          videoId:vId
        },{headers: myheaders})
        .then(response => {
          console.log(response)
        });
    },
    setFollowStatus()
    {
        const myheaders = {
          'deviceId':sessionStorage.getItem('deviceId'),
          'userId':sessionStorage.getItem('userId'),
          'accessToken':sessionStorage.getItem("accessToken"),
        }
        axios.get(`https://api.wakaw.live/v1/api/follow/status?followeeId=` + this.currentVideoObject.user_id,
        {
            headers:myheaders 
        }).then(response => {
          if(response.data.data.status)
          {
            this.followStatus = 'Following';
          }
        })
    },
    followUser()
    {
        const myheaders = {
          'deviceId':sessionStorage.getItem('deviceId'),
          'userId':sessionStorage.getItem('userId'),
          'accessToken':sessionStorage.getItem("accessToken"),
        }
        axios.post(`https://api.wakaw.live/v1/api/follow`,
        {
          following_id:this.currentVideoObject.user_id
        },{headers: myheaders})
        .then(response => {
          //console.log(response.data.status)
          if(response.data.status == "success")
          {
            this.followStatus = 'Following'
          }
        })
        .catch(e => {
          this.errors.push(e)
        })
    },
    showProduct(item)
    {
      console.log(item);
      this.showProductObj = true;
      this.displayMode = 0;
      this.product.url = item.images[0];
      this.product.title = item.name;
      this.product.price = item.variantMrp;
    },
    getVideoAnalytics()
    {
        const myheaders = {
          'deviceId':sessionStorage.getItem('deviceId'),
          'userId':sessionStorage.getItem('userId'),
          'accessToken':sessionStorage.getItem("accessToken"),
        }
        axios.post(`https://api.wakaw.live/v1/api/analytics/video/`+this.currentVideoObject.video_id+'/view',
        {
          tagged_orders:[],
          brandId:this.$route.params.brand
        },{headers: myheaders})
        .then(response => {
          console.log(response);
          axios.get(`https://api.wakaw.live/v1/api/analytics/video/` + this.currentVideoObject.video_id,
          {
            headers:myheaders 
          }).then(response => {
            console.log(response.data.data);
            var info = response.data.data;
            if(info.viewsCount)
            {
              this.viewsCurrVideo = info.viewsCount;
            }
            if(info.UserStatus.likes==true || info.UserStatus.likes=='true')
            {
              this.isVideoLiked = true;
            }
          })
          .catch(e => {
            this.errors.push(e)
          })
        })
        .catch(e => {
          this.errors.push(e)
        })
    },
    likeCurrVideo()
    {
        const myheaders = {
          'deviceId':sessionStorage.getItem('deviceId'),
          'userId':sessionStorage.getItem('userId'),
          'accessToken':sessionStorage.getItem("accessToken"),
        }
        axios.post(`https://api.wakaw.live/v1/api/analytics/video/`+this.currentVideoObject.video_id+'/view',
        {
          tagged_orders:[],
          brandId:this.$route.params.brand
        },{headers: myheaders})
        .then(response => {
          console.log(response);
          axios.post(`https://api.wakaw.live/v1/api/analytics/video/`+this.currentVideoObject.video_id+'/like',
          {
          },{headers: myheaders})
          .then(response => {
            console.log(response);
            this.isVideoLiked = true;
          })
          .catch(e => {
            this.errors.push(e)
          })
        })
        .catch(e => {
          this.errors.push(e)
        })
    },
    openGiftPromo()
    {
      this.isGiftIconClicked = true;
      this.displayMode = 0;
      const myheaders = {
          'deviceId':sessionStorage.getItem('deviceId'),
          'userId':sessionStorage.getItem('userId'),
          'accessToken':sessionStorage.getItem("accessToken"),
        }
        axios.get(`https://api.wakaw.live/v1/promos/NEWWAKAW10`,
        {
          headers: myheaders
        })
        .then(response => {
          console.log(response);
          this.promocodeDiscountPercent = response.data.data.discount;
          this.promocodeText = response.data.data.description;
          this.showPromoCodeModal = true;
        })
        .catch(e => {
          this.errors.push(e)
        })
    },
    startLoader() {
			this.myloader = this.$loading.show({ canCancel: false, color:'#34749B' });
		},
		stopLoader() {
			this.myloader.hide();
    },
    redirectBuy(url)
    {
      console.log(url);
      window.open(url, '_newtab');
    },
    fetchProductDetailsInitial()
    {
      console.log('holaxx');
      const myheaders = {
        'deviceId':sessionStorage.getItem('deviceId'),
        'userId':sessionStorage.getItem('userId'),
        'accessToken':sessionStorage.getItem("accessToken"),
      }
      console.log(myheaders);
      axios.get(`https://api.wakaw.live/v1/api/products/` + this.currentVideoObject.video_id,
        {
          headers:myheaders 
        }).then(response => {
          console.log(response);
          this.productDetailsList = response.data.data.product_ids;
          this.vendorUrl = response.data.data.product_ids[0].vendor_url;
          this.brandname = response.data.data.product_ids[0].brand_name;
          for(let i in this.productDetailsList)
          {
            let myObj = this.productDetailsList[i].ecommRedirect;
            let myList = [];
            for(let prop in myObj)
            {
              let tempObj = {};
              if(Object.prototype.hasOwnProperty.call(myObj, prop))
              {
                tempObj.platform = prop;
                tempObj.link = myObj[prop];
                myList.push(tempObj);
              }
            }
            this.productDetailsList[i].ecommRedirect = myList;
          }
        })
        .catch(e => {
          this.errors.push(e)
        })
    },
    fetchProductDetails()
    {
      console.log('hola');
      this.startLoader();
      const myheaders = {
        'deviceId':sessionStorage.getItem('deviceId'),
        'userId':sessionStorage.getItem('userId'),
        'accessToken':sessionStorage.getItem("accessToken"),
      }
      console.log(myheaders);
      axios.get(`https://api.wakaw.live/v1/api/products/` + this.currentVideoObject.video_id,
        {
          headers:myheaders 
        }).then(response => {
          this.stopLoader();
          console.log(response);
          this.productDetailsList = response.data.data.product_ids;
          this.displayMode = 2;
          this.vendorUrl = response.data.data.product_ids[0].vendor_url;
        })
        .catch(e => {
          this.stopLoader();
          this.errors.push(e)
        })
    },
  playTheVideo()
  {
    this.isVideoMuted = false;
    var vid = document.getElementById("video-elem");
    vid.muted = false;
  },
  pauseTheVideo()
  {
    this.isVideoMuted = true;
    var vid = document.getElementById("video-elem");
    vid.muted = true;
  },
  fullScreenVideo()
  {
    var elem = document.getElementById("video-elem");
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { 
      elem.msRequestFullscreen();
    }
  },
  playLiveVideo(index,item)
  {
    console.log(index);
    console.log(item);
    this.currLivestreamObj = item;
    const myheaders = {
          'deviceId':sessionStorage.getItem('deviceId'),
          'userId':sessionStorage.getItem('userId'),
          'accessToken':sessionStorage.getItem("accessToken"),
        }
        axios.post(`https://api.wakaw.live/v1/event/join`,
        { 
          eventId: item.id
        },{headers: myheaders})
        .then(response => {
          console.log(response);
          this.joinLiveStream(response.data.data.channelName,response.data.data.token,sessionStorage.getItem('userId'));
        });
  },
  joinLiveStream(channel,token,userid)
  {
    console.log('hola world '+channel+' '+token+' '+userid);
    this.streamClient = AgoraRTC.createClient({
          mode: 'live',
          codec: "h264",

      });
      this.streamClient.setClientRole("audience", function() {
        console.log("setaudience success");
      }, function(e) {
        console.log("setaudience failed", e);
      })

      this.streamClient.init('a63c8d066bfd4fea934babb381d47984',() => console.log("AgoraRTC client initialized") ,this.handleFail);
      var vm = this;
      this.subscribeStreamEventsForLiveStreaming();
      this.streamClient.join(token,channel,userid, (uid)=>{
          console.log(uid);
          vm.isLiveStreamActive = true;
          vm.displayMode = 0;
      },vm.handleFail);
  },
  playVideo(index,i)
  {
    if(this.isLiveStreamActive)
    {
      this.streamClient.leave();
      this.streamClient = {};
      document.getElementById("remote-container-live").innerHTML = "";
      document.getElementById("canvas-container-live").innerHTML = "";
      this.isLiveStreamActive = false;
    }
    console.log('hola');
    this.currentVideoObject = i;
    var video = document.getElementById('video-elem');
    var source = document.createElement('source');

    source.setAttribute('src', i.videoUrls.MP4[0]);
    video.appendChild(source);
    video.load();
    video.play();
    console.log(source);
    try
    {
      this.getVideoAnalytics();
    }
    catch(e)
    {
      console.log(e)
    }
    this.isVideoLiked = false;
    this.videoUserName = i.username;
    this.setFollowStatus();
  },
  getBrandID()
  {
      console.log(this.$route.params.brand);
      if(this.$route.params.brand.length==0)
      {
        this.$router.push({ path: '/404notfound'}); 
      }
      else
      {
        console.log(this.$route.query.uid);
        console.log(this.$route.params.videoId);
        if(this.$route.query.uid && this.$route.params.videoId)
        {
          this.recordVideoShare(this.$route.query.uid,this.$route.params.videoId);
        } 
      }
      this.getVideoFeedForBrand(this.currPage,this.$route.params.brand,0);
  },
  getVideoFeedForBrand(currPage,brandID,pageChange)
  {
      console.log(brandID)
      this.startLoader();
        const myheaders = {
        'deviceId':sessionStorage.getItem('deviceId'),
        'userId':sessionStorage.getItem('userId'),
        'accessToken':sessionStorage.getItem("accessToken"),
      }
      console.log(myheaders);
      axios.get(`https://api.wakaw.live/v1/api/videofeeds?pageNumber=`+currPage+`&pageSize=10&brandId=`+brandID,
        {
          headers:myheaders 
        }).then(response => {
          this.stopLoader();
          console.log(response);
           
          this.videoDetailsList = response.data.data.videoDetails;

          if(this.videoDetailsList.length == 0 )
          {
            //this.$router.push({ path: '/404notfound'}); 
          }
          else
          {
            this.currPage = this.currPage + pageChange;
            var mydiv = document.getElementById('scrolldiv');
            mydiv.scrollTop = 0;
          }
          console.log(this.videoDetailsList);
          try{
            this.videoUserName = this.videoDetailsList[0].username;
          }
          catch(e)
          {
            console.log(e);
          }
          this.currentVideoObject = this.videoDetailsList[0];

          try{
            this.getVideoAnalytics();
          }
          catch(e)
          {
            console.log(e);
          }
          try{
            this.setFollowStatus();
          }
          catch(e)
          {
            console.log(e);
          }
          this.fetchProductDetailsInitial();
        })
        .catch(e => {
          this.errors.push(e)
        })
  },
  getVideoFeedForBrandPageChange(currPage,brandID,pageChange)
  {
      var myPage = currPage + pageChange;
      console.log(brandID)
      this.startLoader();
        const myheaders = {
        'deviceId':sessionStorage.getItem('deviceId'),
        'userId':sessionStorage.getItem('userId'),
        'accessToken':sessionStorage.getItem("accessToken"),
      }
      console.log(myheaders);
      axios.get(`https://api.wakaw.live/v1/api/videofeeds?pageNumber=`+myPage+`&pageSize=10&brandId=`+brandID,
        {
          headers:myheaders 
        }).then(response => {
          this.stopLoader();
          console.log(response);

          if(response.data.data.length > 0 )
          {
            this.videoDetailsList = response.data.data;
            this.currPage = this.currPage + pageChange;
            var mydiv = document.getElementById('scrolldiv');
            mydiv.scrollTop = 0;
          }
          else
          {
              this.$confirm("No further videos found", "Error", "error")
              .then(r => {
                console.log(r);
                console.log("OK selected.");
              })
              .catch(() => {
                console.log("OK not selected.");
              });
          }
        })
        .catch(e => {
          this.stopLoader();
          this.errors.push(e)
        })
  },
  myEventHandler(e) {
    console.log(e)
    document.getElementById('sidebar').style.height = (document.getElementById('videobox').clientHeight)/1.0233 + 'px';
    // your code for handling resize...
  }
  }
}
</script>

<style scoped>
.mobile_view.icons{
  position: absolute; 
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.header{
    position: absolute;
    height: 55px;
    left: 0%;
    right: 0%;
    top: 0px;
    display:inline;
    background: #424242;
    box-sizing: border-box;
    
}
.feedTitle{

height: 24px;
margin: 0;
margin-left: -150px;
width:fit-content;
padding: 14px;
font-family: Roboto;
font-style: normal;
font-weight: 500;
font-size: 20px;
color:white;
align-items: center;
}
.vjs_video_578-dimensions {
    width: 500px !important;
    height: 576px !important;
}
::-webkit-scrollbar {
    width: 0px !important;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: transparent !important;
}
.container-fluid{
  padding: 0px;
}
.row{
  margin-left: 0;
  margin-right: 0;
}
.videoSection {
        position: relative;
        overflow: hidden;
    }
.callsection {
        position: relative;
        overflow: hidden;
    }
    video{
      width: 100%;
      /*max-height: 700px !important;*/
      height: 99vh;
      object-fit:cover !important;
      transform: scale(1.22, 1);
    }



@media (max-width: 580px) {

  .shoe_box{
    width:35%;
  }

}


@media (max-width: 389px) {
.mobile_view{
  width:100vw !important;
}
}
@media (min-height: 500px) and (max-width: 650px){
.mobile_view{
  width: 100vw !important;
}
video{
  transform: scale(1.39,1);
  width:100%;
}
}
@media (min-width: 650px){
.mobile_view{
  width: 390px !important;
  padding-bottom: 25px;
}
}
@media (min-width: 700px){
.mobile_view{
  padding-left: 0px !important;
}
}
.mobile_view{
  width: 389px;
  height: fit-content;
  /*padding-top:4px;*/
  padding-bottom: 9px;
  box-shadow: 0 8px 15px rgba(14, 14, 14, 0.175);
  margin: 0 auto;
      border: 1px solid #020310;
        padding-left: 1px;
    padding-right: 1px;
    /*padding-top: 3px;*/
    max-height: 100%;
}
.shoe_box {
    position: relative;
    z-index: 10;
    width: fit-content;
        max-width: 35%;
}
.video_box {
    position: absolute;
    top: 0;
    z-index: 1;
    width:100%
}
.videoSection img.expand{
      position: absolute;
    z-index: 111;
    /* left: 0px; */
    top: 65px;
    right: 9px;
    color: white;
    margin: 1%;
    font-size: 30px;
    cursor:pointer
}
div.viewcount
{
    position: absolute;
    z-index: 111;
    top: 2.1%;
    right: 36%;
    color: white;
    margin: 1%;
    font-size: 16px;
}
.videoSection i.fa-eye{
    position: absolute;
    z-index: 111;
    top: 2%;
    right: 43%;
    color: white;
    margin: 1%;
    font-size: 25px;
    cursor:pointer
}
.videoSection img.brandlogo{
        position: absolute;
    z-index: 111;
    top: 0px;
    right: 0%;
    color: white;
    margin: 1%;
    font-size: 30px;
    cursor:pointer
}
.videoSection img.wtvlogo{
    position: absolute;
    z-index: 111;
    top: 0px;
    left: 1%;
    color: white;
    margin: 1%;
    font-size: 30px;
    cursor:pointer
}
.videoSection img.mute{
      position: absolute;
    z-index: 111;
    /* left: 0px; */
    top: 120px;
    right: 8px;
    color: white;
    margin: 1%;
    font-size: 35px;
    cursor:pointer
}
.videoSection img.speak{
      position: absolute;
    z-index: 111;
    /* left: 0px; */
    top: 120px;
    right: 8px;
    color: white;
    margin: 1%;
    font-size: 35px;
    cursor:pointer
}
.videoSection img.camera{
      position: absolute;
    z-index: 111;
    /* left: 0px; */
    top:53%;
    right: 9px;
    color: white;
    margin: 1%;
    font-size: 39px;
    cursor:pointer
}
.streamdiv img.camera{
      position: absolute;
    z-index: 111;
    /* left: 0px; */
    top:44%;
    right: 9px;
    color: white;
    margin: 1%;
    font-size: 39px;
    cursor:pointer
}
.streamdiv img.giftlive{
      position: absolute;
    z-index: 111;
    /* left: 0px; */
    top:34%;
    right: 9px;
    color: white;
    margin: 1%;
    font-size: 39px;
    cursor:pointer
}
.videoSection img.like{
      position: absolute;
    z-index: 111;
    /* left: 0px; */
    top:69%;
    right: 9px;
    color: white;
    margin: 1%;
    font-size: 38px;
    cursor:pointer;
}
.videoSection img.gift{
    position: absolute;
    z-index: 111;
    /* left: 0px; */
    top:45%;
    right: 9px;
    color: white;
    margin: 1%;
    font-size: 38px;
    cursor:pointer;
}
.videoSection img.vcall{
    position: absolute;
    z-index: 111;
    /* left: 0px; */
    top:36%;
    right: 5px;
    color: white;
    margin: 1%;
    font-size: 40px;
    cursor:pointer;
    border-radius: 50%;
}
img.playv{
  border-radius: 50%;
}
.videoSection img.share{
        position: absolute;
    z-index: 111;
    /* left: 0px; */
    top:77%;
    right: 9px;
    color: rgb(136, 133, 133);
    margin: 1%;
    font-size: 39px;
        cursor:pointer
}
.videoSection img.cart{
      position: absolute;
    z-index: 111;
    /* left: 0px; */
    top:61%;
    right: 9px;
    color: white;
    margin: 1%;
    font-size: 40px;
    cursor:pointer
}
.streamdiv img.cart{
      position: absolute;
    z-index: 111;
    /* left: 0px; */
    top:54%;
    right: 9px;
    color: white;
    margin: 1%;
    font-size: 40px;
    cursor:pointer
}
.videoSection div.userprofile{
        position: absolute;
    z-index: 111;
    /* left: 0px; */
    top:12%;
    left: 0px;
    color: white;
    cursor:pointer;
    width: fit-content;
    padding-right:7px;
    background: black;
    opacity: 0.6;
    height: 46px;
    border-top-right-radius: 23px;
    border-bottom-right-radius: 23px;
    display: flex;
    align-items: center;
}
button:active{
  border:none;
}
.callsection img.vcallmute{
        position: absolute;
    z-index: 111;
    /* left: 0px; */
    bottom:2%;
    left: 20%;
    color: white;
    margin: 1%;
    font-size: 40px;
    cursor:pointer
}
.callsection img.vuser{
        position: absolute;
    z-index: 111;
    /* left: 0px; */
    bottom:2%;
    left: 43%;
    color: white;
    margin: 1%;
    font-size: 40px;
    cursor:pointer
}
.callsection img.vcomment{
        position: absolute;
    z-index: 111;
    /* left: 0px; */
    bottom:2%;
    right: 35%;
    color: white;
    margin: 1%;
    font-size: 40px;
    cursor:pointer
}
.callsection img.calld{
        position: absolute;
    z-index: 111;
    /* left: 0px; */
    bottom:2%;
    right: 20%;
    color: white;
    margin: 1%;
    font-size: 40px;
    cursor:pointer
}
.callsection img.calld1{
        position: absolute;
    z-index: 111;
    /* left: 0px; */
    top:1%;
    right: 9px;
    color: white;
    margin: 1%;
    font-size: 40px;
    cursor:pointer
}
.videoSection img.userprofileicon{
      border-radius: 50%;
      opacity: 1.0;
          top:12%;
        margin-left: 3px;
        margin-right: 3px;
}
.hh img.playv{
         position: absolute;
    z-index: 111;
    /* left: 0px; */
    color: white;
    margin: 33%;
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
 }

.navbar{
  padding-top:2px !important;
  padding-bottom: 2px !important;
}
.hh{
  margin:6px;
  border:0.5px solid #C0C0C0;
  border-radius: 3px;
}
.buy-btn{
  width:90%;
  background-color: #F43B55 !important;
  border-radius: 4px;
  color:white;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  border: none;
  margin-top:6px
}
.buy-btn:active{
  width:90%;
  background-color: #F43B55 !important;
  border-radius: 4px;
  color:white;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  border: none;
  margin-top:6px
}
.vodal, .vodal-mask{
  z-index: 200 !important;
}
.mymodal >>>.vodal-dialog{
  padding:0 !important;
}
.center-it{
  display: flex;
  justify-content: center;
  margin-left:-15px;
}
.sm-icons{
  padding-left: 5px;
  padding-right: 5px;
  cursor:pointer;
}
.disabledbutton {
    pointer-events: none;
}
button:hover{border:none !important;outline: none;}

button:focus{border:none !important;outline: none;}
.swal2-popup{
  max-width:280px !important;
}
.activeS{
  color:black !important
}
</style>